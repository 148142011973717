import {configureStore} from '@reduxjs/toolkit';
import {api} from './services/api';
import authSlice from "./authSlice";
import userSlice from "./userSlice";
import {createLogger} from 'redux-logger';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware()
      .concat(api.middleware)
      //.concat(rtkQueryErrorLogger);

    if (process.env.NODE_ENV === `development`) {
      middlewares.push(createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error
      }));
    }

    return middlewares;
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
