import React, { useCallback, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Edit,  
} from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import ModeratorModal from "../../components/Modals/ModeratorModal";
import Header from "../../components/Header/Header";
import PasswordModal from "../../components/Modals/PasswordModal";
import { useMutationToasts, useSupabase } from "../../utils/hooks";
import { Database } from "../../utils/supabase";

type User = Database["protector"]["Tables"]["admins_moders"]["Row"];

export default function ModeratorUsers() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();
  const [target, setTarget] = useState("");
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<User | null>(null);

  const fetchUsers = async (): Promise<User[]> => {
    const { data, error } = await supabase.from("admins_moders").select("*");
    if (error) throw error;
    return data;
  };

  const {
    data: users,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  const createUserMutation = useMutation({
    mutationFn: async (newUser: Partial<User>) => {
      const { data, error } = await supabase
        .from("admins_moders")
        .insert(newUser)
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      setOpen(false);
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: async (updatedUser: Partial<User>) => {
      const { data, error } = await supabase
        .from("admins_moders")
        .update(updatedUser)
        .eq("id", updatedUser.id!)
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      setOpen(false);
    },
  });

  const updatePasswordMutation = useMutation({
    mutationFn: async ({ id, password }: { id: string; password: string }) => {
      const { data, error } = await supabase
        .from("admins_moders")
        .update({ password })
        .eq("id", id)
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => setOpen(false),
  });

  const editModeratorHandler = useCallback((row: User) => {
    setCurrentRow(row);
    setTarget("edit_moderator");
    setOpen(true);
  }, []);

  // const changePasswordHandler = useCallback((row: User) => {
  //   setCurrentRow(row);
  //   setTarget("change_password");
  //   setOpen(true);
  // }, []);

  // const addModeratorHandler = useCallback(() => {
  //   setTarget("add_new_moderator");
  //   setCurrentRow(null);
  //   setOpen(true);
  // }, []);

  function handleValidSubmitModeratorModal(formValues: Partial<User>) {
    if (target === "add_new_moderator") {
      createUserMutation.mutate(formValues);
    } else if (target === "edit_moderator" && currentRow) {
      updateUserMutation.mutate({
        login: formValues.login,
        full_name: formValues.full_name,
        role: formValues.role,
        disabled: formValues.disabled,
        id: currentRow.id,
      });
    }
  }

  function handleValidSubmitPasswordModal(formValues: {
    password: string;
    repeatPassword: string;
  }) {
    if (currentRow) {
      updatePasswordMutation.mutate({
        id: currentRow.id,
        password: formValues.password,
      });
    }
  }

  useMutationToasts({
    isLoading: createUserMutation.isPending,
    isSuccess: createUserMutation.isSuccess,
    error: createUserMutation.error ?? undefined,
    successMsg: "El usuario ha sido creado.",
  });

  useMutationToasts({
    isLoading: updateUserMutation.isPending,
    isSuccess: updateUserMutation.isSuccess,
    error: updateUserMutation.error ?? undefined,
    successMsg: "El usuario ha sido modificado.",
  });

  useMutationToasts({
    isLoading: updatePasswordMutation.isPending,
    isSuccess: updatePasswordMutation.isSuccess,
    error: updatePasswordMutation.error ?? undefined,
    successMsg: "La contraseña ha sido cambiada.",
  });

  if (isLoading) return <div>Cargando...</div>;
  if (error) return <div>Error cargando datos.</div>;

  const isModeratorModalOpen =
    target === "add_new_moderator" ||
    (currentRow !== null && target === "edit_moderator");

  const isPasswordModalOpen =
    currentRow !== null && target === "change_password";

  return (
    <>
      <Header />
      <TableContainer component={Paper} sx={{ flexGrow: 1 }}>
        <Table aria-label="simple table" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell align="center">Deshabilitado</TableCell>
              {/* <TableCell align="right">
                <Button variant="contained" onClick={addModeratorHandler}>
                  <Add /> Agregar moderador
                </Button>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.login}</TableCell>
                <TableCell>{row.full_name}</TableCell>
                <TableCell>
                  {row.role === "moderator" ? "Moderador" : "Administrador"}
                </TableCell>
                <TableCell align="center">
                  {row.disabled ? <CheckBox /> : <CheckBoxOutlineBlank />}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="Modificar"
                    onClick={() => editModeratorHandler(row)}
                  >
                    <Edit />
                  </IconButton>
                  {/* <IconButton
                    aria-label="Cambiar contraseña"
                    onClick={() => changePasswordHandler(row)}
                  >
                    <Password />
                  </IconButton> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isModeratorModalOpen && (
        <ModeratorModal
          open={open}
          onClose={() => setOpen(false)}
          target={target}
          currentRow={currentRow}
          onValidSubmit={handleValidSubmitModeratorModal}
        />
      )}
      {isPasswordModalOpen && (
        <PasswordModal
          open={open}
          onClose={() => setOpen(false)}
          currentRow={currentRow}
          onValidSubmit={handleValidSubmitPasswordModal}
        />
      )}
    </>
  );
}
