import React, { createContext, useContext, useEffect, useState } from "react";
import { useSupabase } from "../utils/hooks";
import { User } from "@supabase/supabase-js";

interface AuthContextType {
  user: User | null;
  role: string | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  role: null,
  loading: true,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const supabase = useSupabase();  

  useEffect(() => {    
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {        
        console.log(event);
        setUser(session?.user ?? null);
        if (session?.user) {                              
          supabase
            .from("admins_moders")
            .select("role")
            .eq("id", session.user.id)
            .single()
            .then(({ data, error }) => {              
              if (!error && data) {
                setRole(data.role);
              } else {
                setRole(null);
              }
              setLoading(false);
            });
        } else {
          setRole(null);
          setLoading(false);
        }
      }
    );
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [supabase]);

  return (
    <AuthContext.Provider value={{ user, role, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
