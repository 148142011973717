import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Details,
  Edit,
  Search,
  TableChart,
  BarChart as BarChartIcon,
} from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";

import Header from "../../components/Header/Header";
import IncidentReportModal from "../../components/Modals/IncidentReportModal";
import IncidentReportDetailsModal from "../../components/Modals/IncidentReportDetailsModal";
import { useMutationToasts, useSupabase } from "../../utils/hooks";
import { PaginationCriteria } from "../../utils/types";
import { Database } from "../../utils/supabase";
import dayjs, { Dayjs } from "dayjs";

type IncidentReportView =
  Database["protector"]["Views"]["incident_report_view"]["Row"];
type IncidentReportTable =
  Database["protector"]["Tables"]["incident_report"]["Row"];

type IncidentReport = IncidentReportView &
  IncidentReportTable & {
    users: {
      created_by_user_full_name: string;
      created_by_user_email: string;
    };
    municipality: string;
  };
type IncidentImage = {
  incident_image_id: number;
  url: string;
};

type ReportType =
  | "dumping"
  | "excavation"
  | "tree_cutting"
  | "water_pollution"
  | "water_leak"
  | "other";

export default function IncidentReports() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();
  const DEFAULT: PaginationCriteria = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] =
    useState<PaginationCriteria>(DEFAULT);
  const [searchText, setSearchText] = useState<string>("");

  const [target, setTarget] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<IncidentReport | null>(null);
  const [reportType, setReportType] = useState<ReportType | "">("");
  const [reportStatus, setReportStatus] = useState<string>("UNRESOLVED");
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(new Date(new Date().setMonth(new Date().getMonth() - 1)))
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(new Date()));

  const [municipios, setMunicipios] = useState<
    { municipio_id: number; municipio: string }[]
  >([]);
  const [selectedMunicipio, setSelectedMunicipio] = useState<number | "">("");

  const [viewMode, setViewMode] = useState<'table' | 'chart'>('table');

  const fetchMunicipios = async () => {
    const { data, error } = await supabase
      .from("municipio")
      .select("municipio_id, municipio")
      .order("municipio");
    if (error) {
      console.error("Error fetching municipios:", error);
    } else {
      setMunicipios(data);
    }
  };

  useEffect(() => {
    fetchMunicipios();
  }, []);

  const fetchReports = async (): Promise<{
    records: IncidentReport[];
    totalRecords: number;
  }> => {
    const from = (paginationState.pageNumber - 1) * paginationState.pageSize;
    const to = from + paginationState.pageSize - 1;

    let query = supabase
      .from("incident_report")
      .select("*")
      .ilike("title", `%${searchText}%`)
      .order("created_at", { ascending: false });

    if (viewMode === 'table') {
      query = query.range(from, to);
    }

    let countQuery = supabase
      .from("incident_report")
      .select("*", { count: "exact", head: true })
      .ilike("title", `%${searchText}%`);

    if (reportType) {
      query = query.eq("type", reportType);
      countQuery = countQuery.eq("type", reportType);
    }
    if (reportStatus) {
      query = query.eq("status", reportStatus);
      countQuery = countQuery.eq("status", reportStatus);
    }
    if (selectedMunicipio) {
      query = query.eq("municipio_id", selectedMunicipio);
      countQuery = countQuery.eq("municipio_id", selectedMunicipio);
    }
    if (startDate) {
      query = query.gte("created_at", startDate.toISOString());
      countQuery = countQuery.gte("created_at", startDate.toISOString());
    }
    if (endDate) {
      query = query.lte("created_at", endDate.toISOString());
      countQuery = countQuery.lte("created_at", endDate.toISOString());
    }

    const [{ data, error }, { count }] = await Promise.all([query, countQuery]);

    if (error) throw error;
    return { records: data as IncidentReport[], totalRecords: count ?? 0 };
  };

  const {
    data: reportsData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "incidentReports",
      paginationState,
      searchText,
      reportType,
      reportStatus,
      selectedMunicipio,
      startDate,
      endDate,
      viewMode,
    ],
    queryFn: fetchReports,
  });

  const fetchReportImages = async (
    reportId: number
  ): Promise<IncidentImage[]> => {
    const { data, error } = await supabase
      .from("incident_image")
      .select("incident_image_id, image_id")
      .eq("incident_report_id", reportId);

    if (error) throw error;

    const imageUrls = await Promise.all(
      data.map(async (item) => {
        const { data: imageData, error: imageError } = await supabase
          .from("image")
          .select("url")
          .eq("image_id", item.image_id)
          .single();

        if (imageError) throw imageError;

        return {
          incident_image_id: item.incident_image_id as number,
          url: imageData.url as string,
        };
      })
    );
    if (error) throw error;
    return imageUrls as IncidentImage[];
  };

  const { data: reportImages, refetch: refetchImages } = useQuery({
    queryKey: ["reportImages", currentRow?.incident_report_id, target],
    queryFn: () => fetchReportImages(currentRow!.incident_report_id!),
    enabled: !!currentRow?.incident_report_id,
  });

  const putReportModeration = useMutation({
    mutationFn: async (updatedRow: Partial<IncidentReport>) => {
      const { data, error } = await supabase
        .from("incident_report")
        .update(updatedRow)
        .eq("incident_report_id", updatedRow.incident_report_id!);
      if (error) throw error;
      return data;
    },
  });

  const handleValidSubmitEditModal = useCallback(
    (formValues: Partial<IncidentReport>) => {
      if (currentRow) {
        const updatedRow = { ...currentRow, ...formValues };
        putReportModeration.mutate(updatedRow);
      }
    },
    [currentRow, putReportModeration]
  );

  useMutationToasts({
    isLoading: putReportModeration.isPending,
    isSuccess: putReportModeration.isSuccess,
    error: putReportModeration.error ?? undefined,
    successMsg: "El reporte ha sido modificado.",
    onSuccess: () => {
      handleClose();
      queryClient.invalidateQueries({ queryKey: ["incidentReports"] });
    },
  });

  useEffect(() => {
    if (target === "details_report" && currentRow?.incident_report_id) {
      refetchImages();
    }
  }, [currentRow, target, refetchImages]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = (row: IncidentReport) => {
    setCurrentRow(row);
  };

  const handleEditPressed = useCallback((row: IncidentReport) => {
    handleClick(row);
    setTarget("edit_report");
    setOpen(true);
  }, []);

  const handleDetailPressed = useCallback((row: IncidentReport) => {
    handleClick(row);
    setTarget("details_report");
    setOpen(true);
  }, []);

  const isModeratorTabModalOpen =
    currentRow !== null && target === "edit_report";

  const isDetailsModalOpen = currentRow !== null && target === "details_report";

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPaginationState({ ...paginationState, pageNumber: 1 });
  };

  const handleReportTypeChange = (event: SelectChangeEvent<string>) => {
    setReportType(event.target.value as ReportType | "");
  };

  const handleReportStatusChange = (event: SelectChangeEvent<string>) => {
    setReportStatus(event.target.value);
  };

  const handleMunicipioChange = (event: SelectChangeEvent<number | "">) => {
    setSelectedMunicipio(event.target.value as number | "");
  };

  const handleViewModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newViewMode: 'table' | 'chart' | null
  ) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const getReportTypeLabel = (type: ReportType | ""): string => {
    switch (type) {
      case "dumping":
        return "Vertedero clandestino";
      case "excavation":
        return "Movimientos de tierra o Construcción";
      case "tree_cutting":
        return "Corte de arboles";
      case "water_pollution":
        return "Descarga de aguas usadas";
      case "water_leak":
        return "Pérdida de agua potable";
      case "other":
        return "Otro";
      case "":
        return "Todos";
      default:
        return "Desconocido";
    }
  };

  const reportTypes: ReportType[] = [
    "dumping",
    "excavation", 
    "tree_cutting",
    "water_pollution",
    "water_leak",
    "other"
  ];

  const reportColors = [
    "#8c564b", // Marrón para vertederos
    "#ff7f0e", // Naranja para excavaciones
    "#2ca02c", // Verde para corte de árboles
    "#1f77b4", // Azul para contaminación del agua
    "#d62728", // Rojo para fugas de agua
    "#9467bd"  // Púrpura para otros
  ];

  return (
    <Box>
      <Header />
      <Box sx={{ p: 2, display: "flex", gap: 2, flexWrap: "wrap", alignItems: "center" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar por título"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="report-type-label">Tipo de reporte</InputLabel>
          <Select
            labelId="report-type-label"
            value={reportType}
            onChange={handleReportTypeChange}
            label="Tipo de reporte"
          >
            <MenuItem value="">Todos</MenuItem>
            {(
              [
                "dumping",
                "excavation",
                "tree_cutting",
                "water_pollution",
                "water_leak",
                "other",
              ] as ReportType[]
            ).map((type) => (
              <MenuItem key={type} value={type}>
                {getReportTypeLabel(type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="report-status-label">Estatus</InputLabel>
          <Select
            labelId="report-status-label"
            value={reportStatus}
            onChange={handleReportStatusChange}
            label="Estatus"
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="UNRESOLVED">Sin resolver</MenuItem>
            <MenuItem value="RESOLVED">Resuelto</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="municipio-label">Municipio</InputLabel>
          <Select
            labelId="municipio-label"
            value={selectedMunicipio}
            onChange={handleMunicipioChange}
            label="Municipio"
          >
            <MenuItem value="">Todos</MenuItem>
            {municipios.map((municipio) => (
              <MenuItem
                key={municipio.municipio_id}
                value={municipio.municipio_id}
              >
                {municipio.municipio}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            label="Fecha inicial"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <DatePicker
            label="Fecha final"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
        </LocalizationProvider>
        
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewModeChange}
          aria-label="Modo de visualización"
        >
          <ToggleButton value="table" aria-label="tabla">
            <TableChart />
          </ToggleButton>
          <ToggleButton value="chart" aria-label="gráfico">
            <BarChartIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box sx={{ p: 2, minHeight: 400 }}>
        {viewMode === 'chart' ? (
          <BarChart
            xAxis={[{
              scaleType: 'band',
              data: ['Tipo de reporte']
            }]}
            series={reportTypes.map(type => ({
              data: [reportsData?.records.filter(report => report.type === type).length ?? 0],
              label: getReportTypeLabel(type),
            }))}
            colors={reportColors}   
            height={500}
          />
        ) : (
          <TableContainer component={Paper} sx={{ minHeight: '400px' }}>
            <Table aria-label="tabla de reportes de incidentes">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="left">Tipo</TableCell>
                  <TableCell align="left">Fecha de creación</TableCell>
                  <TableCell align="left">Título</TableCell>
                  <TableCell align="left">Descripción</TableCell>
                  <TableCell align="left">Municipio</TableCell>
                  <TableCell align="left">Estatus</TableCell>
                  <TableCell align="center">Censurado</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography color="error">Error cargando datos.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  reportsData?.records.map((row) => (
                    <TableRow
                      key={row.incident_report_id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.incident_report_id}</TableCell>
                      <TableCell align="left">
                        {getReportTypeLabel(row.type as ReportType)}
                      </TableCell>
                      <TableCell align="left">
                        {dayjs(row.created_at).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left" className="description_row">
                        {row.description}
                      </TableCell>
                      <TableCell align="left">{row.municipality}</TableCell>
                      <TableCell align="left">
                        {row.status === "RESOLVED" ? "Resuelto" : "Sin resolver"}
                      </TableCell>
                      <TableCell align="center">
                        {row.flagged ? <CheckBox /> : <CheckBoxOutlineBlank />}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="detalles"
                          title="Ver detalles"
                          onClick={() => handleDetailPressed(row)}
                        >
                          <Details />
                        </IconButton>
                        <IconButton
                          aria-label="editar"
                          title="Modificar"
                          onClick={() => handleEditPressed(row)}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          {reportsData && (
            <Pagination
              count={Math.ceil(reportsData.totalRecords / DEFAULT.pageSize)}
              page={paginationState.pageNumber}
              onChange={(event, page) => {
                setPaginationState({
                  ...paginationState,
                  pageNumber: page,
                });
              }}
            />
          )}
        </Box>
      </Box>
      {isModeratorTabModalOpen && (
        <IncidentReportModal
          open={open}
          onClose={handleClose}
          target={target}
          currentRow={currentRow}
          onValidSubmit={handleValidSubmitEditModal}
        />
      )}
      {isDetailsModalOpen && (
        <IncidentReportDetailsModal
          open={open}
          onClose={handleClose}
          target={target}
          currentRow={currentRow}
          reportImages={reportImages || []}
        />
      )}
    </Box>
  );
}