import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/authSlice";
import { useFormik } from "formik";
import { useSupabase } from "../../utils/hooks";
import { useAuth } from "../../context/AuthContext";

const LoginPage = () => {
  const [authenticationError, setAuthenticationError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const supabase = useSupabase();
  const { user, loading } = useAuth();

  useEffect(() => {
    if (!loading && user) {
      navigate("/dashboard");
    }
  }, [user, loading, navigate]);

  function validate(values) {
    const errors = {};

    if (!values.login) {
      //@ts-ignore
      errors.login = "obligatorio";
    }

    if (!values.password) {
      //@ts-ignore
      errors.password = "obligatorio";
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validate,
    onSubmit: async (values, actions) => {
      setAuthenticationError(null);

      if (supabase) {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: values.login,
          password: values.password,
        });
        if (error) {
          //@ts-ignore
          setAuthenticationError(error.message);
          actions.setSubmitting(false);
          return;
        }
        if (data.user) {
          const { data: admins_moders, error: admins_moders_error } =
            await supabase
              .from("admins_moders")
              .select("*")              
              .eq("id", data.user.id)
              .eq('disabled', false);
          if (admins_moders_error) {
            console.error(admins_moders_error);
            return;
          }
          if (admins_moders.length === 0) {
            setAuthenticationError(
              // @ts-ignore
              "No tienes permisos para acceder a esta aplicación"
            );
            return;
          }
          dispatch(setToken(data.session.access_token));
          navigate("/dashboard");
          return;
        }
      }
      actions.setSubmitting(false);
    },
  });

  if (loading) return null;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontFamily="'National 2 Compressed'"
        >
          Administración de Ciudadano Protector
        </Typography>

        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          {Boolean(authenticationError) && (
            <Typography color="red">{authenticationError}</Typography>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            id="login"
            label="Usuario"
            name="login"
            autoComplete="login"
            autoFocus
            inputProps={{ maxLength: 30 }}
            onChange={formik.handleChange}
            value={formik.values.login}
            error={Boolean(formik.touched.login && formik.errors.login)}
            helperText={formik.errors.login && formik.errors.login}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            inputProps={{ maxLength: 30 }}
            onChange={formik.handleChange}
            value={formik.values.password}
            autoComplete="current-password"
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={
              (formik.errors.password && formik.errors.password) ||
              authenticationError
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Ingresar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;