import React, { useCallback, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Add, Edit, Search } from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import ConfirmModal from "../../components/Modals/ConfirmModal";
import ConceptModal from "../../components/Modals/ConceptModal";
import Header from "../../components/Header/Header";

import "./Concepts.css";
import { Database } from "../../utils/supabase";
import { useMutationToasts, useSupabase } from "../../utils/hooks";

type Concept = Database["protector"]["Tables"]["concept"]["Row"];

export default function Concepts() {
  const queryClient = useQueryClient();
  const supabase = useSupabase();
  const [target, setTarget] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentRow, setCurrentRow] = useState<Concept | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  const fetchConcepts = async () => {
    const { data, error } = await supabase
      .from("concept")
      .select("*")
      .ilike("name", `%${searchText}%`)
      .order("concept_id");
    if (error) throw error;
    return data;
  };

  const fetchCategories = async () => {
    const { data, error } = await supabase.from("concept_category").select("*");
    if (error) throw error;
    return data;
  };

  const {
    data: concepts,
    isLoading: loadingConcepts,
    error: errorConcepts,
  } = useQuery({
    queryKey: ["concepts", searchText],
    queryFn: fetchConcepts,
  });
  const {
    data: categories,
    isLoading: loadingCategories,
    error: errorCategories,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  const createConceptMutation = useMutation({
    mutationFn: async (newConcept: Concept) => {
      const { data, error } = await supabase.from("concept").insert(newConcept);
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["concepts"] });
    },
  });

  const updateConceptMutation = useMutation({
    mutationFn: async (updatedConcept: Concept) => {
      const { data, error } = await supabase
        .from("concept")
        .update(updatedConcept)
        .eq("concept_id", updatedConcept.concept_id);
      if (error) throw error;
      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["concepts"] });
    },
  });

  useMutationToasts({
    isLoading:
      createConceptMutation.isPending || updateConceptMutation.isPending,
    isSuccess:
      createConceptMutation.isSuccess || updateConceptMutation.isSuccess,
    error:
      createConceptMutation.error ?? updateConceptMutation.error ?? undefined,
    successMsg:
      target === "add_concept"
        ? "El concepto ha sido creado."
        : "El concepto ha sido modificado.",
  });

  const confirmModalClose = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTarget("");
  }, []);

  const handleEditConcept = useCallback((row: Concept) => {
    setCurrentRow(row);
    setTarget("edit_concept");
    setOpen(true);
  }, []);

  const handleAddConcept = useCallback(() => {
    setCurrentRow(null);
    setTarget("add_concept");
    setOpen(true);
  }, []);

  const handleValidSubmit = useCallback(
    (formValues: Concept) => {
      if (target === "add_concept") {
        createConceptMutation.mutate(formValues, {
          onSuccess: () => {
            handleClose();
          },
        });
      } else if (target === "edit_concept" && currentRow) {
        updateConceptMutation.mutate(
          { ...formValues, concept_id: currentRow.concept_id },
          {
            onSuccess: () => {
              handleClose();
            },
          }
        );
      }
    },
    [
      target,
      currentRow,
      createConceptMutation,
      updateConceptMutation,
      handleClose,
    ]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const isConceptModalOpen =
    target === "add_concept" ||
    (currentRow !== null && target === "edit_concept");
  const isConfirmModalOpen =
    currentRow !== null &&
    (target === "disable_concept" || target === "delete_concept");
  
  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header />
      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar por nombre"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
        <TableContainer component={Paper} sx={{ flexGrow: 1, overflow: "auto" }}>
          <Table stickyHeader aria-label="tabla de conceptos">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Nombre</TableCell>
                <TableCell align="left">Categoría</TableCell>
                <TableCell align="left">Descripción 1</TableCell>
                <TableCell align="left">Descripción 2</TableCell>
                <TableCell align="left">Hashtags</TableCell>
                <TableCell align="left">Imagen</TableCell>
                <TableCell align="left">Imagen c/leyenda</TableCell>
                <TableCell align="center">
                  <Button variant="contained" onClick={handleAddConcept}>
                    <Add /> Agregar
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ flexGrow: 1 }}>
              {loadingConcepts ? (
                <TableRow sx={{ height: "100%" }}>
                  <TableCell colSpan={9} align="center" sx={{ height: "100%" }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : errorConcepts ? (
                <TableRow sx={{ height: "100%" }}>
                  <TableCell colSpan={9} align="center" sx={{ height: "100%" }}>
                    <Typography color="error">Error cargando datos.</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                concepts?.map((row) => (
                  <TableRow
                    key={row.concept_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.concept_id}</TableCell>
                    <TableCell align="left">
                      <div className="description_row" title={row.name}>
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row.category_id}</TableCell>
                    <TableCell align="left">
                      <div
                        className="description_row"
                        title={row.description_html ?? ""}
                      >
                        {row.description_html}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        className="description_row"
                        title={row.description2_html ?? ""}
                      >
                        {row.description2_html}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="hashtags_row" title={row.hashtags ?? ""}>
                        {row.hashtags}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="picture_row">{row.image_url}</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="picture_row">{row.detail_image_url}</div>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="Modificar"
                        onClick={() => handleEditConcept(row)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isConceptModalOpen && (
          <ConceptModal
            open={open}
            onClose={handleClose}
            target={target}
            currentRow={currentRow}
            conceptCategories={categories || []}
            onValidSubmit={handleValidSubmit}
          />
        )}
        {isConfirmModalOpen && (
          <ConfirmModal
            openConfirm={openConfirm}
            confirmModalClose={confirmModalClose}
            target={target}
            currentRow={currentRow}
          />
        )}
      </Box>
    </Box>
  );
}
