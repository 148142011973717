import React, { useEffect } from 'react';
import { useFormik } from 'formik';

import {
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, MenuItem, Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import { validationSchemaModerate } from '../../constants/ValidationSchemas';
import { formFieldsModerate } from '../../constants/FormFields';

type Props = {
  open: boolean;
  onClose(): void;
  target: string;
  currentRow: any;
  onValidSubmit(formValues: FormValues): void;
};

type FormValues = {
  status: string;
  flagged: boolean;
  flagged_comment: string;
};

type formikFields = 'status' | 'flagged' | 'flagged_comment';

const IncidentReportModal = ({
  open,
  onClose = () => {},
  currentRow,
  onValidSubmit = () => {},
}: Props) => {
  const formik = useFormik({
    initialValues: {
      status: currentRow?.status ?? 'UNRESOLVED',
      flagged: currentRow?.flagged ?? false,
      flagged_comment: currentRow?.flagged_comment ?? '',
    } as FormValues,
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchemaModerate,
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        status: currentRow?.status ?? 'UNRESOLVED',
        flagged: currentRow?.flagged ?? false,
        flagged_comment: currentRow?.flagged_comment ?? '',
      },
    });    
  }, [currentRow]);

  function handleSubmit() {
    onValidSubmit(formik.values);
  }

  function handleClose() {
    onClose();
    formik.resetForm();
  }

  return (
    <Dialog fullWidth open={open} onClose={handleSubmit}>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <DialogTitle>
          <div> Moderar reporte de incidente</div>
        </DialogTitle>
        <DialogContent>
          {/*<pre style={{fontStyle: 'small'}}>{JSON.stringify(currentRow, null, 2)}</pre>*/}

          {formFieldsModerate.map(({ field, label }) => {
            if(field === 'flagged') {
              return (
                <div key={field}>
                  <FormControlLabel control={<Checkbox
                    checked={formik.values.flagged}
                    id={field}
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />} label={label} />
                </div>
              );
            }
            if(field === 'status') {
              return (
                <div key={field}>
                  <Select
                    error={Boolean(
                      formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                    )}
                    margin="dense"
                    id={field}
                    label={label}
                    fullWidth
                    multiline
                    required
                    variant="standard"
                    name={field}
                    value={formik.values[field as formikFields]}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="UNRESOLVED">Sin resolver</MenuItem>
                    <MenuItem value="RESOLVED">Resuelto</MenuItem>
                  </Select>
                </div>
              );
            }
            return (
              <div key={field}>
                <TextField
                  error={
                    !!(
                      formik.touched[field as formikFields] &&
                      formik.errors[field as formikFields]
                    )
                  }
                  helperText={
                    formik.touched[field as formikFields] &&
                    formik.errors[field as formikFields]
                  }
                  margin="dense"
                  id={field}
                  label={label}
                  type="text"
                  fullWidth
                  multiline
                  variant="standard"
                  name={field}
                  value={formik.values[field as formikFields]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors[field as formikFields] &&
                  formik.touched[field as formikFields] && (
                    <div>{formik.errors[field as formikFields]}</div>
                  )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleSubmit}
            type="button"
          >
            Guardar
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default IncidentReportModal;
