import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSupabase } from "../../utils/hooks";
import { useQuery } from "@tanstack/react-query";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const supabase = useSupabase();
  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const { data, error } = await supabase.auth.getUser();
      if (data.user) {
        const { data: adminData, error: adminError } = await supabase
          .from("admins_moders")
          .select("*")
          .eq("id", data.user.id)
          .single();

        if (adminError) throw adminError;
        return { ...data.user, ...adminData };
      }
      if (error) throw error;
      return data;
    },
  });

  const handleLogout = () => {
    supabase.auth.signOut();
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { label: "Dashboard", to: "/dashboard" },
    { label: "Reportes", to: "/incidentReports" },
    { label: "Conceptos", to: "/concepts" },
    ...(user?.role === "admin" ? [{ label: "Moderadores", to: "/moderatorUsers" }] : []),
    { label: "Salir", to: "/login", onClick: handleLogout },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            fontFamily="'National 2 Compressed'"
            fontWeight="bold"
            fontSize={28}
          >
            Ciudadano Protector Admin
          </Typography>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <ButtonGroup>
              {menuItems.map((item) => (
                <Button
                  key={item.label}
                  variant="contained"
                  size="large"
                  color={item.label === "Salir" ? "error" : "primary"}
                  component={NavLink}
                  to={item.to}
                  onClick={item.onClick}
                >
                  {item.label}
                </Button>
              ))}
            </ButtonGroup>
          </Box>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.label}
                component={NavLink}
                to={item.to}
                onClick={() => {
                  handleClose();
                  if (item.onClick) item.onClick();
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
