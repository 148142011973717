import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./views/Login/LoginPage";
import ModeratorUsers from "./views/ModeratorUsers/ModeratorUsers";
import Concepts from "./views/Concepts/Concepts";
import IncidentReports from "./views/IncidentReports/IncidentReports";
import { ToastContainer } from "react-toastify";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  createTheme,
  ThemeProvider,
  CircularProgress,
  Box,
} from "@mui/material";
import "./App.css";
import { useAuth, AuthProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Dashboard from "./views/Dashboard/Dashboard";

interface PrivateRouteProps {
  children: JSX.Element;
}

interface AdminRouteProps {
  children: JSX.Element;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  // return children;
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return user ? children : <Navigate to="/login" />;
}

function AdminRoute({ children }: AdminRouteProps) {
  // return children;
  const { role, loading } = useAuth();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return role === "admin" ? children : <Navigate to="/concepts" />;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(66, 74, 220, 1)",
    },
  },
  typography: {
    fontFamily: "'Questa Sans'",
    button: {
      fontFamily: "'National 2 Compressed'",
      fontSize: 18,
    },
  },
});

const queryClient = new QueryClient();

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/incidentReports"
        element={
          <PrivateRoute>
            <IncidentReports />
          </PrivateRoute>
        }
      />
      <Route
        path="/concepts"
        element={
          <PrivateRoute>
            <Concepts />
          </PrivateRoute>
        }
      />
      <Route
        path="/moderatorUsers"
        element={
          <PrivateRoute>
            <AdminRoute>
              <ModeratorUsers />
            </AdminRoute>
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <AppRoutes />
            </ThemeProvider>
          </BrowserRouter>
          <ToastContainer theme="colored" />
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  );
}
