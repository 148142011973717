import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import Header from "../../components/Header/Header";
import { useSupabase } from "../../utils/hooks";

type ReportType =
  | "dumping"
  | "excavation"
  | "tree_cutting"
  | "water_pollution"
  | "water_leak"
  | "other";

export default function Dashboard() {
  const supabase = useSupabase();
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, 'month').startOf('day')
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('day'));
  const [userCount, setUserCount] = useState<number>(0);
  const [reportCount, setReportCount] = useState<number>(0);
  const [reportTypes, setReportTypes] = useState<{
    [key in ReportType]: number;
  }>({
    dumping: 0,
    excavation: 0,
    tree_cutting: 0,
    water_pollution: 0,
    water_leak: 0,
    other: 0,
  });

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = async () => {
    if (startDate && endDate) {
      const { count: userCount } = await supabase
        .from("users_count")
        .select("*", { count: "exact", head: true })
        .gte("created_at", startDate.toISOString())
        .lte("created_at", endDate.toISOString());

      const { count: reportCount, data: reportData } = await supabase
        .from("incident_report")
        .select("type", { count: "exact" })
        .gte("created_at", startDate.toISOString())
        .lte("created_at", endDate.toISOString());

      setUserCount(userCount || 0);
      setReportCount(reportCount || 0);

      const typeCounts = {
        dumping: 0,
        excavation: 0,
        tree_cutting: 0,
        water_pollution: 0,
        water_leak: 0,
        other: 0,
      };

      reportData?.forEach((report) => {
        typeCounts[report.type as ReportType]++;
      });

      setReportTypes(typeCounts);
    }
  };

  const getReportTypeLabel = (type: ReportType): string => {
    switch (type) {
      case "dumping":
        return "Vertedero clandestino";
      case "excavation":
        return "Movimientos de tierra o Construcción";
      case "tree_cutting":
        return "Corte de arboles";
      case "water_pollution":
        return "Descarga de aguas usadas";
      case "water_leak":
        return "Pérdida de agua potable";
      case "other":
        return "Otro";
      default:
        return "Desconocido";
    }
  };

  const reportColors = [
    "#8c564b", // Marrón para vertederos
    "#ff7f0e", // Naranja para excavaciones
    "#2ca02c", // Verde para corte de árboles
    "#1f77b4", // Azul para contaminación del agua
    "#d62728", // Rojo para fugas de agua
    "#9467bd", // Púrpura para otros
  ];

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header />
      <Box sx={{ p: 5, display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h4">Resumen de actividad</Typography>
        <Box
          sx={{            
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label="Fecha inicial"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              label="Fecha final"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
        </Box>
        <Box display="flex" flexDirection={'column'} height={500} justifyContent="space-around">
          <Typography variant="h6">
            Usuarios creados: {userCount}
          </Typography>
          <Typography variant="h6">
            Reportes creados: {reportCount}
          </Typography>
          <PieChart
            series={[
              {
                data: Object.entries(reportTypes).map(([type, count], index) => ({
                  id: index,
                  value: count,
                  label: getReportTypeLabel(type as ReportType),
                  color: reportColors[index],
                })),
              },
            ]}            
          />
        </Box>
      </Box>
    </Box>
  );
}
